import React, { useCallback } from 'react';
import { Report, ReportPathoHighlight, ReportValue, ReportValueMibiType, ResultFormat } from 'interfaces/api';
import { ListItem } from 'components';
import cx from 'classnames';
import { FormatDate, Translate } from 'providers';
import { getCssColor } from 'utils/dom';
import { Color, Message } from 'interfaces';
import { filter, groupBy, map } from 'lodash';
import { MibiTable } from 'modules/reports/components/MibiTable/MibiTable';
import {
  filterMibiTableValues,
  filterNotMibiTableValues,
  hasGraph,
  hasNoGraph,
  hasNoPrevValue,
  hasPathoText,
  hasPreviousGraph,
  hasPrevPathoText,
  hasPrevValue,
  useFormatReportValue,
} from 'modules/reports/utils';
import messages from 'messages';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';

const reportDetailLabels = messages.reports.details.label;

export const useConvertReportValuesToListItems = <T extends ReportValue, >() => {

  const { reportDisplay } = useReportsConfig();
  const formatValue = useFormatReportValue();
  const pathoHighlightType = reportDisplay?.pathoHighlightType || ReportPathoHighlight.Row;

  return useCallback((values: T[], report: Report, Graph: any, idPrefix: string = '') => {

    const fieldContainer = (label: Message, value: any) => (
      <span className={'report-detail-field-container'}>
        <span className={'report-detail-label'}><Translate message={label}/></span>
        <span className={'report-detail-value'}>{value}</span>
      </span>
    );

    const hasPrevValues = !hasNoPrevValue(values);
    const showPathoColumn = hasPathoText(values);
    const showPrevPathoColumn = hasPrevPathoText(values);

    const result: ListItem<T>[] = filterNotMibiTableValues(values).map((value, idx) => {

      const id = idPrefix + value.description + idx;
      const classNames = value.poct ? ['report-detail-is-poct'] : (value.pathological ? ['report-detail-is-patho'] : []);

      const title = value.format === ResultFormat.Result ? value.description : value.result;
      const fields = [];

      if (value.mibi === ReportValueMibiType.NoMibiValue) {
        if (value.format === ResultFormat.Result) {

          if (showPathoColumn) {
            fields.push({
              value: value.pathological || '',
              flipped: true,
              className: 'report-detail-patho-column report-detail-is-patho',
            });
          }

          fields.push({
            label: value.reference.text
              ? fieldContainer(reportDetailLabels.valueRef, value.reference.text + ' ' + value.unit)
              : null,
            value: fieldContainer(reportDetailLabels.value, formatValue(value, report)),
            flipped: true,
            className: cx('report-detail-current-value', 'report-detail-value-column', { ['report-detail-is-patho']: value.pathological }),
          });

          if (showPrevPathoColumn) {
            fields.push({
              value: value?.previous?.pathological || '',
              flipped: true,
              className: cx('report-detail-patho-column', 'report-detail-is-patho', 'is-hidden-mobile', 'report-detail-previous-value'),
            });
          }

          if (hasPrevValue(value)) {
            fields.push({
              label: fieldContainer(reportDetailLabels.previousValueDate, <FormatDate date={value.previous.date} options={{ dateOnly: true }}/>),
              value: fieldContainer(reportDetailLabels.previousValue, formatValue(value.previous, report)),
              flipped: true,
              className: cx('report-detail-previous-value', 'report-detail-value-column', {
                ['report-detail-is-patho']: value.previous.pathological,
                ['report-detail-is-green']: value.pathological && !value.previous.pathological,
              }),
            });
          } else if (hasPrevValues) {
            fields.push({
              value: '',
              className: cx('report-detail-previous-value', 'report-detail-value-column', 'is-hidden-mobile'),
            });
          }

          if (hasGraph(value)) {
            fields.push({
              value: (<Graph value={value}/>),
              className: 'report-detail-graph',
              wide: true,
            });
          } else if (hasPreviousGraph(value)) {
            fields.push({
              value: (<Graph value={value.previous} gray/>),
              className: 'report-detail-graph',
              wide: true,
            });
          } else if (!hasNoGraph(values)) {
            fields.push({ value: '', className: 'report-detail-graph is-hidden-mobile', wide: true });
          }

        } else {
          if (value.meta) {
            fields.push({
              value: value.meta,
              flipped: true,
              className: 'report-detail-text-value',
            });
          } else {
            classNames.push('report-detail-text-only');
          }
        }
      }

      const backgroundColor = value.poct ? getCssColor(Color.Yellow) : (pathoHighlightType === ReportPathoHighlight.Row && value.pathological ? getCssColor(Color.Red) : undefined);

      const body = filter([
        value.mibi === ReportValueMibiType.IsMibiValue && value.result?.length > 0 && (<pre key={'mibi'}><p>{value.result}</p></pre>),
        value.meta?.length > 0 && (
          value.isRtfConverted
            ? <div dangerouslySetInnerHTML={{ __html: value.meta[0] }} key={'meta'}/>
            : <pre key={'meta'}>{value.meta.map((m, i) => <p key={i} dangerouslySetInnerHTML={{ __html: m }}/>)}</pre>
        ),
      ]);

      return {
        id,
        title,
        fields,
        backgroundColor,
        body: body.length > 0 ? body : undefined,
        className: cx(classNames),
        backgroundOpacity: 0.2,
        groupByValue: value.group,
        meta: value,
      };
    });

    const mibiTableValues = filterMibiTableValues(values);

    if (mibiTableValues.length > 0) {
      result.push({
        id: 'mibi-tables',
        title: undefined,
        className: 'report-detail-mibi',
        body: map(groupBy(mibiTableValues, 'group'), (group, mibiName) => {
          return <MibiTable key={mibiName} name={mibiName} values={group}/>;
        }),
      });
    }

    return result;
  }, [pathoHighlightType]);
};
