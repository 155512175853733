import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { default as AntInput, InputProps as AntInputProps } from 'antd/es/input';
import './Input.less';
import { InputRef } from 'antd';

export enum InputFormat {
  Numeric,
  Alphabetic,
  Alphanumeric,
}

type OverwriteProps = {
  format?: InputFormat | string;
};

export type InputProps = Omit<AntInputProps, keyof OverwriteProps> & OverwriteProps;

export const Input = forwardRef<InputRef, InputProps>((props, ref) => {

  const { format, ...rest } = props;

  const inputRef = useRef<InputRef>(null);

  useImperativeHandle(ref, () => {
    if (props.autoFocus) {
      window.setTimeout(() => inputRef.current?.focus?.(), 250);
    }
    return inputRef.current;
  }, []);

  return (
    <AntInput
      ref={inputRef}
      {...rest}
    />
  );
});
