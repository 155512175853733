import { useState } from 'react';
import { AnalysisProperties, OrderWizardRequirement, Report, ReportHistoryValue, ReportValueProperties } from 'interfaces/api';
import { Color } from 'interfaces';
import { getReportValueStatus } from 'modules/reports/utils';
import { ReportValueStatus } from 'modules/reports/interfaces';
import { find, map, uniq } from 'lodash';

export type ExtendedAnalysis = AnalysisProperties & {
  status: ReportValueStatus;
  history: ReportHistoryValue;
  lastValue: ReportValueProperties;
};

type ReportsState = {
  reports?: Report[];
  history?: ReportHistoryValue[];
};

const defaultReportsValues: ReportsState = {
  reports: [],
  history: [],
};

export const useReports = () => {

  const [reportState, setReports] = useState<ReportsState>(defaultReportsValues);

  const getExtendedAnalyses = (requirement: OrderWizardRequirement): ExtendedAnalysis[] => {
    return (requirement.analyses || []).map((analysis) => {

      const history = reportState.history && find(reportState.history, { name: analysis.shortName });
      const status = history ? getReportValueStatus(history.values[history.values.length - 1]) : ReportValueStatus.Default;
      const lastValue = history?.values.length > 0 ? history.values[history.values.length - 1] : undefined;

      return { ...analysis, history, status, lastValue };
    });
  };

  const getFlag = (requirement: OrderWizardRequirement) => {

    const uniqStatus = uniq(map(getExtendedAnalyses(requirement), a => a.status));

    if (uniqStatus.includes(ReportValueStatus.Pathological)) {
      return Color.Red;
    } else if (uniqStatus.includes(ReportValueStatus.Ok)) {
      return Color.Green;
    } else {
      return undefined;
    }
  };

  const reset = () => setReports(defaultReportsValues);
  const { reports, history } = reportState;

  return {
    setReports,
    reports,
    history,
    reset,
    getExtendedAnalyses,
    getFlag,
  };
};
