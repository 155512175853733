import { useCallback, useMemo } from 'react';
import { OrderRuleActionType, OrderRulesMandatoryField, WriteableOrderProperties, WriteableOrderPropertiesSchema } from 'interfaces/api';
import { useOrderRulesContext } from './OrderRulesProvider';
import { useOrdersContext } from 'modules/orders/providers/OrdersProvider';
import { difference, find, flatten, get, keys, omitBy, sum, uniq } from 'lodash';
import { isEmptyValue, splitEnumOptions } from 'utils/helpers';
import { useOfficeDoctorContext } from 'modules/orders/providers/OfficeDoctorProvider';

export const additionalFieldsMandatoryFields = [
  OrderRulesMandatoryField.PatientHeight,
  OrderRulesMandatoryField.PatientWeight,
  OrderRulesMandatoryField.PregnancyWeek,
  OrderRulesMandatoryField.SamplePeriod,
  OrderRulesMandatoryField.SampleAmount,
  OrderRulesMandatoryField.InsuranceName,
  OrderRulesMandatoryField.ScheduleDate,
  OrderRulesMandatoryField.CostUnit,
  OrderRulesMandatoryField.HvCode,
  OrderRulesMandatoryField.VKat,
];

export const standaloneMandatoryFields = [
  OrderRulesMandatoryField.Tnr,
  OrderRulesMandatoryField.Diagnosis,
];

export const allMandatoryFields: OrderRulesMandatoryField[] = difference(
  splitEnumOptions(OrderRulesMandatoryField).map(f => f.value),
  standaloneMandatoryFields,
);

const patientMandatoryFields: OrderRulesMandatoryField[] = difference(
  allMandatoryFields,
  additionalFieldsMandatoryFields,
);

const fieldsWithPattern: Partial<Record<OrderRulesMandatoryField, string>> = omitBy(splitEnumOptions(OrderRulesMandatoryField)
  .map(f => f.value).reduce((previousValue, currentValue) => ({
    ...previousValue,
    [currentValue]: get(WriteableOrderPropertiesSchema, currentValue)?.pattern,
  }), {}), isEmptyValue);

export const useMandatoryFields = () => {

  const { getActionsByType, getBasketErrorsByName } = useOrderRulesContext();

  const { currentOrder, orders, selectedOrderIndex } = useOrdersContext();

  const { officeDoctor: { localisation } } = useOfficeDoctorContext();

  // all mandatory fields from result
  const mandatoryFields: OrderRulesMandatoryField[] = useMemo(
    () => getActionsByType(OrderRuleActionType.MandatoryField)?.map(f => f.field) || [],
    [getActionsByType, currentOrder?.costUnit, localisation],
  );

  const invalidFieldErrors = useMemo(() => {
    return flatten(find(getBasketErrorsByName('InputError'), { idx: selectedOrderIndex || 0 })?.errors.map(e => e.message));
  }, [getBasketErrorsByName, selectedOrderIndex]);

  const invalidFields = useMemo(() => {
    return uniq([
      ...invalidFieldErrors.map(e => e.instancePath?.substring(1).split('/').join('.')),
      ...keys(fieldsWithPattern).filter(k => get(currentOrder, k)?.length && !new RegExp(get(WriteableOrderPropertiesSchema, k)?.pattern).test(get(currentOrder, k))),
    ]);
  }, [invalidFieldErrors, currentOrder]);

  const filterInvalidFields = useCallback((checkFields = allMandatoryFields) => {
    return invalidFields.filter(e => checkFields.includes(e));
  }, [invalidFields]);

  const getMandatoryFieldsForOrder = useCallback((order: WriteableOrderProperties, checkFields = allMandatoryFields) => {
    return checkFields.filter(field => mandatoryFields.includes(field) && isEmptyValue(get(order, field)));
  }, [mandatoryFields]);

  const getMandatoryPatientFieldsForOrder = useCallback(
    (order: WriteableOrderProperties) => getMandatoryFieldsForOrder(order, patientMandatoryFields),
    [getMandatoryFieldsForOrder],
  );

  const showAdditionalInfoWarningBadge = useMemo(
    () => currentOrder && (!!getMandatoryFieldsForOrder(currentOrder, additionalFieldsMandatoryFields).length || !!filterInvalidFields(additionalFieldsMandatoryFields).length),
    [getMandatoryFieldsForOrder, filterInvalidFields, currentOrder],
  );

  const showPatientWarningBadge = useMemo(
    () => currentOrder
      ? !!getMandatoryPatientFieldsForOrder(currentOrder).length || !!filterInvalidFields(patientMandatoryFields).length
      : !!sum(orders.map(order => getMandatoryFieldsForOrder(order).length)),
    [currentOrder, orders, getMandatoryPatientFieldsForOrder, getMandatoryFieldsForOrder, filterInvalidFields],
  );

  const showPatientWarningBadgeForPoolOrder = useCallback(
    (order: WriteableOrderProperties) => getMandatoryFieldsForOrder(order).length,
    [getMandatoryFieldsForOrder],
  );

  return {
    mandatoryFields,
    invalidFields,
    showPatientWarningBadge,
    showPatientWarningBadgeForPoolOrder,
    showAdditionalInfoWarningBadge,
    getMandatoryPatientFieldsForOrder,
  };

};
