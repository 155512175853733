import React, { useEffect, useState } from 'react';
import { Container, FormItem, FormRenderProps, Switch, Tab, Tabs } from 'components';
import {
  DmpSpecialGroup,
  DmpType,
  Feature,
  FormType,
  KbvCovidLegalBasis,
  KbvCovidLocation,
  KbvCovidLocationFacility,
  KbvCovidRvoManualOptions,
  KbvCovidTest,
  OrderWizardLocalization,
  WriteableOrderProperties,
} from 'interfaces/api';
import { useGuard } from 'containers';
import messages from 'messages';
import { useOfficeDoctorContext, useOrdersConfig, useOrdersContext } from 'modules/orders/providers';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';
import * as Forms from 'modules/orders/containers/OrderWizard/components/PatientForm/Forms';
import { splitEnumOptions, splitObjectOptions } from 'utils/helpers';
import { Divider } from 'antd';
import cx from 'classnames';
import styles from 'modules/orders/containers/OrderWizard/components/PatientForm/styles.module.less';
import { map } from 'lodash';
import { usePatientInvoiceToOptions, useSwitzerlandBags } from 'modules/orders/utils.tsx';

export const PatientForm: React.ComponentType<FormRenderProps<WriteableOrderProperties>> = (props) => {

  const { disabled, Property, Input, value, onChange } = props;

  const tabs: Tab[] = [];

  const labels = messages.orders.additionalFields;
  const personLabels = messages.general.person;
  const animalLabels = messages.general.animal;

  const { officeDoctor: { localisation }, kisDisabled, wizardSettings } = useOfficeDoctorContext();

  const { uniqueFormTypes } = useBasketContext();
  const { preferences } = useOrdersConfig();
  const { setOrderProperties } = useOrdersContext();

  const guard = useGuard();

  const { switzerlandBagsConfig, mapSwitzerlandInsuranceValues } = useSwitzerlandBags();

  const copyToPatientActivationSwitch = wizardSettings?.preferences?.orderWizardCopyToPatientActivationSwitch;
  const [copyToPatientActive, setCopyToPatientActive] = useState(!copyToPatientActivationSwitch || value.switzerland.billCopyTo?.length > 0);

  useEffect(() => {
    if (copyToPatientActivationSwitch) {
      props.onChange({ switzerland: { ...props.value.switzerland, billCopyToEmail: props.value.switzerland.billCopyTo } });
    }
  }, [copyToPatientActivationSwitch]);

  tabs.push({
    title: labels.patientData,
    children: (
      <Container padding scrollY>

        {(localisation === OrderWizardLocalization.GER || localisation === OrderWizardLocalization.AUT || localisation === OrderWizardLocalization.KIS) && (
          <Property property={'patient'} disabled={kisDisabled || disabled} component={Forms.PatientDataForm}/>
        )}

        {localisation === OrderWizardLocalization.VDE && (
          <Property property={'animal'} component={Forms.AnimalDataForm}/>
        )}

        {localisation === OrderWizardLocalization.CHE && (
          <Property property={'patient'} component={Forms.PatientDataSwitzerlandForm}/>
        )}

        {localisation === OrderWizardLocalization.HUN && (
          <Property property={'patient'} component={Forms.PatientDataHungaryForm}/>
        )}
      </Container>
    ),
  });

  if (localisation === OrderWizardLocalization.AUT) {
    tabs.push({
      title: labels.insuranceData,
      children: (
        <Container padding scrollY>
          <Property property={'patient'} component={Forms.InsuranceDataForm}/>
        </Container>
      ),
    });
  }

  if (localisation !== OrderWizardLocalization.AUT && !preferences.orderWizardHideAdditionalInfo) {
    tabs.push({
      title: labels.additionalInformation,
      children: (
        <Container padding scrollY>

          {(localisation === OrderWizardLocalization.GER || localisation === OrderWizardLocalization.FEN) && (

            <Property property={'patient'}>
              {({ Input }) => (
                <Container grid={'49%'}>
                  <Input property={'primaryCarePhysicianBsnr'} label={labels.primaryCarePhysicianBsnr} maxLength={20} floating/>
                  <Input property={'primaryCarePhysicianLanr'} label={labels.primaryCarePhysicianLanr} maxLength={20} floating/>
                </Container>
              )}
            </Property>
          )}

          {(localisation === OrderWizardLocalization.KIS) && (
            <>
              <Container grid={'49%'}>
                <Property property={'patient'}>
                  {({ Input }) => (
                    <>
                      <Input property={'patientIdentification'} label={labels.patientIdentification} maxLength={20} floating disabled={kisDisabled}/>
                      <Input property={'caseNumber'} label={labels.caseNumber} maxLength={50} floating disabled={kisDisabled}/>
                    </>
                  )}
                </Property>
              </Container>

              <Container grid={'32%'}>
                <Input property={'hospitalStation'} label={labels.hospitalStation} maxLength={50} floating disabled={kisDisabled}/>
                <Property property={'patient'}>
                  {({ Input }) => (
                    <>
                      <Input property={'hospitalRoomNumber'} label={labels.hospitalRoomNumber} maxLength={100} floating disabled={kisDisabled}/>
                      <Input property={'hospitalBedNumber'} label={labels.hospitalBedNumber} maxLength={100} floating disabled={kisDisabled}/>
                    </>
                  )}
                </Property>
              </Container>
            </>
          )}

          {(localisation === OrderWizardLocalization.GER || localisation === OrderWizardLocalization.FEN) && (

            <Property property={'patient'}>
              {({ Select }) => (
                <Container grid={'49%'}>

                  <Select
                    floating
                    label={labels.dmp}
                    property={'diseaseManagementProgram'}
                    options={splitEnumOptions(DmpType, labels.dmpTypes)}
                  />
                  <Select
                    floating
                    label={labels.dmpSpecialGroup}
                    property={'dmpSpecialGroup'}
                    options={splitEnumOptions(DmpSpecialGroup, labels.dmpSpecialGroupTypes)}
                  />

                </Container>
              )}
            </Property>
          )}

          {(localisation === OrderWizardLocalization.GER || localisation === OrderWizardLocalization.FEN || localisation === OrderWizardLocalization.KIS) && (
            <Forms.PatientInsuranceForm {...props}/>
          )}

          {localisation === OrderWizardLocalization.VDE && (
            <Property property={'animal'} component={Forms.AnimalAdditionalInfoForm} label={animalLabels.owner.title}/>
          )}

          {localisation === OrderWizardLocalization.FEN && (
            <Container grid={'49%'}>
              <Input property={'accidentTime'} label={personLabels.accidentTime} maxLength={20} floating/>
              <Input property={'documentReferenceNumber'} label={personLabels.documentReferenceNumber} maxLength={20} floating/>
            </Container>
          )}

          {localisation === OrderWizardLocalization.CHE && (
            <Container grid={'49%'}>

              <Property property={'patient'}>
                {({ Select }) => (
                  <Select
                    floating
                    property={'invoiceTo'}
                    label={labels.invoiceTo}
                    options={usePatientInvoiceToOptions(preferences.orderWizardHideInsuranceInInvoiceTo)}
                  />
                )}
              </Property>

              <Property property={'switzerland'}>
                {({ Input }) => (
                  <>
                    <Input
                      property={'billToOther1'}
                      label={labels.patient.switzerland.billToOther1}
                      maxLength={50}
                      disabled={!['Andere', 'Versicherung'].includes(value.patient.invoiceTo)}
                      floating
                    />
                    <Input
                      property={'billToOther2'}
                      label={labels.patient.switzerland.billToOther2}
                      maxLength={50}
                      disabled={!['Andere', 'Versicherung'].includes(value.patient.invoiceTo)}
                      floating
                    />
                    <Input
                      property={'billToOther3'}
                      label={labels.patient.switzerland.billToOther3}
                      maxLength={50}
                      disabled={!['Andere', 'Versicherung'].includes(value.patient.invoiceTo)}
                      floating
                    />
                    <Input
                      property={copyToPatientActivationSwitch ? 'billCopyToEmail' : 'billCopyTo'}
                      label={labels.patient.switzerland.billCopyTo}
                      maxLength={150}
                      floating
                      disabled={!copyToPatientActive}
                    />
                    {copyToPatientActivationSwitch && (
                      <FormItem label={labels.patient.switzerland.billCopyToSwitch} className={'form-switch-container'}>
                        <Switch value={copyToPatientActive} onChange={checked => setCopyToPatientActive(checked)}/>
                      </FormItem>
                    )}
                    <Input
                      property={'notes'}
                      label={labels.patient.switzerland.notes}
                      maxLength={50}
                      floating
                    />
                  </>
                )}
              </Property>

              <Property property={'patient'}>
                {({ Input, Select, value: patient }) => (
                  <>
                    {switzerlandBagsConfig.value?.chBags
                      ? (
                        <Select
                          floating
                          label={labels.patient.switzerland.bag}
                          property={'bag'}
                          options={map(splitObjectOptions(switzerlandBagsConfig.value.chBags), o => ({ value: o.value, label: `${o.value} (${o.label})` }))}
                          onChange={(bag) => {
                            const mapped = mapSwitzerlandInsuranceValues({
                              ...patient,
                              bag,
                              globalLocationNumber: '',
                              insuranceName: '',
                            });

                            const { insuranceName } = mapped;

                            setOrderProperties({ insuranceName });
                            onChange({ insuranceName, patient: { ...mapped } });
                          }}
                        />
                      )
                      : (
                        <Input
                          property={'bag'}
                          label={labels.patient.switzerland.bag}
                          maxLength={5}
                          floating
                        />
                      )}

                    <Input property={'cardNumber'} label={labels.patient.switzerland.cardNumber} maxLength={20} floating/>

                    {switzerlandBagsConfig.value?.chGlns
                      ? (
                        <Select
                          floating
                          label={labels.patient.switzerland.globalLocationNumber}
                          property={'globalLocationNumber'}
                          options={splitObjectOptions(switzerlandBagsConfig.value.chGlns, true)}
                          onChange={(globalLocationNumber) => {
                            const mapped = mapSwitzerlandInsuranceValues({
                              ...patient,
                              globalLocationNumber,
                              bag: '',
                              insuranceName: '',
                            });

                            const { insuranceName } = mapped;

                            setOrderProperties({ insuranceName });
                            onChange({ insuranceName, patient: { ...mapped } });
                          }}
                        />
                      )
                      : (
                        <Input
                          property={'globalLocationNumber'}
                          label={labels.patient.switzerland.globalLocationNumber}
                          maxLength={13}
                          floating
                        />
                      )}
                  </>
                )}
              </Property>

            </Container>
          )}

          {localisation === OrderWizardLocalization.HUN && (
            <Property property={'hungary'}>
              {({ Input }) => (
                <>
                  <Input property={'motherLastName'} label={labels.patient.hungary.motherLastName} maxLength={20} floating/>
                  <Input property={'motherFirstName'} label={labels.patient.hungary.motherFirstName} maxLength={15} floating/>
                  <Input property={'icd1'} label={labels.patient.hungary.icd1} maxLength={6} floating/>
                  <Input property={'icd2'} label={labels.patient.hungary.icd2} maxLength={6} floating/>
                  <Input property={'icd3'} label={labels.patient.hungary.icd3} maxLength={6} floating/>
                  <Input property={'treatmentReason'} label={labels.patient.hungary.treatmentReason} maxLength={1} floating/>
                  <Input property={'invoice'} label={labels.patient.hungary.invoice} maxLength={2} floating/>
                  <Input property={'insuranceNumber'} label={labels.patient.hungary.insuranceNumber} maxLength={18} floating/>
                </>
              )}
            </Property>
          )}

        </Container>
      ),
    });

  }

  guard({ feature: Feature.KBVMuster10C }, () => {
    if (uniqueFormTypes.includes(FormType.KBVMuster10C)) {

      const riskCharacteristicsLocation = props.value.covid?.riskCharacteristicsLocation;
      const riskCharacteristicsLocationType = props.value.covid?.riskCharacteristicsLocationType;
      const testReason = props.value.covid?.testReason;

      tabs.push({
        title: labels.covid19Info10C,
        children: (
          <Container padding scrollY>
            <Property property={'covid'}>
              {({ Select, Switch, onChange }) => (
                <>
                  <Container grid={'49%'}>
                    <Select
                      label={labels.patient.covid.testNumber}
                      property={'testNumber'}
                      options={splitEnumOptions(KbvCovidTest, messages.general.kbvCovidTest)}
                      floating
                      allowClear
                    />

                    <div>&nbsp;</div>

                  </Container>

                  <Divider/>

                  <Container grid={'49%'}>
                    <Switch
                      label={labels.patient.covid.testReason}
                      value={testReason === 2}
                      onChange={c => onChange({ testReason: c ? 2 : null })}
                    />
                  </Container>

                  <Divider/>

                  <Container grid={'49%'}>
                    <Select
                      label={labels.patient.covid.riskCharacteristicsLocation}
                      property={'riskCharacteristicsLocation'}
                      options={splitEnumOptions(KbvCovidLocation, messages.general.kbvCovidLocation)}
                      floating
                      allowClear
                    />

                    <Select
                      label={labels.patient.covid.riskCharacteristicsLocationType}
                      property={'riskCharacteristicsLocationType'}
                      options={splitEnumOptions(KbvCovidLocationFacility, messages.general.kbvCovidLocationType)}
                      disabled={!riskCharacteristicsLocation}
                      value={!riskCharacteristicsLocation ? null : riskCharacteristicsLocationType}
                      floating
                      allowClear
                    />
                  </Container>

                  <Divider/>

                  <Container>
                    <Switch property={'agreed'} label={labels.patient.covid.agreed}/>
                  </Container>

                  <Divider/>
                </>
              )}
            </Property>

            <Property property={'patient'}>
              {({ Input }) => (
                <Container>
                  <Input property={'phone'} label={personLabels.phone} floating/>
                </Container>
              )}
            </Property>
          </Container>
        ),
      });
    }
  });

  guard({ feature: Feature.KBVMusterOegd }, () => {
    if (uniqueFormTypes.includes(FormType.KBVMusterOegd)) {

      const legalOrderBasis = props.value.covid?.legalOrderBasis;
      const legalReason = props.value.covid?.legalReason;
      const kvSpecial = props.value.covid?.kvSpecial;
      const riskCharacteristicsLocation = props.value.covid?.riskCharacteristicsLocation;
      const riskCharacteristicsLocationType = props.value.covid?.riskCharacteristicsLocationType;

      const showBadge = (!legalOrderBasis)
        || (legalOrderBasis === KbvCovidLegalBasis.RvoTestV && !legalReason)
        || (legalOrderBasis === KbvCovidLegalBasis.RegionalSpecialAgreement && !kvSpecial);

      tabs.push({
        title: labels.covid19InfoOegd,
        badge: showBadge && { count: '!' },
        children: (
          <Container padding scrollY>
            <Property property={'covid'}>
              {({ Select, Input, Switch }) => (
                <>
                  <Container grid={'49%'}>
                    <Select
                      label={labels.patient.covid.legalOrderBasis}
                      property={'legalOrderBasis'}
                      options={splitEnumOptions(KbvCovidLegalBasis, messages.general.kbvCovidLegalBasis)}
                      floating
                      className={cx(!legalOrderBasis && styles.requiredField)}
                    />

                    <Input
                      property={'kvSpecial'}
                      label={labels.patient.covid.kvSpecial}
                      disabled={legalOrderBasis !== KbvCovidLegalBasis.RegionalSpecialAgreement}
                      value={legalOrderBasis !== KbvCovidLegalBasis.RegionalSpecialAgreement ? null : kvSpecial}
                      floating
                      className={cx((legalOrderBasis === KbvCovidLegalBasis.RegionalSpecialAgreement && !kvSpecial) && styles.requiredField)}
                    />
                  </Container>

                  <Container grid={'99%'}>
                    <Select
                      label={labels.patient.covid.legalReason}
                      property={'legalReason'}
                      options={splitEnumOptions(KbvCovidRvoManualOptions, messages.general.kbvCovidRvo)}
                      disabled={legalOrderBasis !== KbvCovidLegalBasis.RvoTestV}
                      value={legalOrderBasis !== KbvCovidLegalBasis.RvoTestV ? null : legalReason}
                      floating
                      className={cx((legalOrderBasis === KbvCovidLegalBasis.RvoTestV && !legalReason) && styles.requiredField)}
                    />
                  </Container>

                  <Divider/>

                  <Container grid={'49%'}>

                    <Switch property={'confirmationDiagnostics'} label={labels.patient.covid.confirmationDiagnostics}/>
                    {/* removed due to v2.2022 (https://labuniq.atlassian.net/browse/M10-10)
                    <Switch property={'variantDiagnostics'} label={labels.patient.covid.variantDiagnostics}/>
*/}

                  </Container>

                  <Divider/>

                  <Container grid={'49%'}>
                    <Select
                      label={labels.patient.covid.riskCharacteristicsLocation}
                      property={'riskCharacteristicsLocation'}
                      options={splitEnumOptions(KbvCovidLocation, messages.general.kbvCovidLocation)}
                      disabled={legalOrderBasis === KbvCovidLegalBasis.DirectPayer}
                      value={legalOrderBasis === KbvCovidLegalBasis.DirectPayer ? null : riskCharacteristicsLocation}
                      floating
                      allowClear
                    />

                    <Select
                      label={labels.patient.covid.riskCharacteristicsLocationType}
                      property={'riskCharacteristicsLocationType'}
                      options={splitEnumOptions(KbvCovidLocationFacility, messages.general.kbvCovidLocationType)}
                      disabled={!riskCharacteristicsLocation || legalOrderBasis === KbvCovidLegalBasis.DirectPayer}
                      value={!riskCharacteristicsLocation || legalOrderBasis === KbvCovidLegalBasis.DirectPayer ? null : riskCharacteristicsLocationType}
                      floating
                      allowClear
                    />
                  </Container>

                  <Divider/>

                  <Container grid={'49%'}>
                    <Input
                      property={'oegdInvoiceTo'}
                      label={labels.patient.covid.oegdInvoiceTo}
                      disabled={legalOrderBasis !== KbvCovidLegalBasis.RegionalSpecialAgreement}
                      value={legalOrderBasis !== KbvCovidLegalBasis.RegionalSpecialAgreement ? null : props.value.covid?.oegdInvoiceTo}
                      floating
                    />

                    <Input property={'healthService'} label={labels.patient.covid.healthService} floating/>
                    <Input property={'healthServiceZipCode'} label={labels.patient.covid.healthServiceZipCode} floating/>
                  </Container>

                  <Divider/>

                  <Container>
                    <Switch property={'agreed'} label={labels.patient.covid.agreed}/>
                  </Container>

                  <Divider/>
                </>
              )}
            </Property>

            <Property property={'patient'}>
              {({ Input }) => (
                <Container>
                  <Input property={'phone'} label={personLabels.phone} floating/>
                  <p>&nbsp;</p>
                </Container>
              )}
            </Property>
          </Container>
        ),
      });
    }
  });

  return (
    <Tabs tabs={tabs}/>
  );

};
