import React, { useCallback, useMemo } from 'react';
import { Color, SortGrouping } from 'interfaces';
import { Feature, Gender, Report, ReportStatus, ReportValue, ReportValueMibiType, ReportValueProperties, ResultGraphicFormat, UserType } from 'interfaces/api';
import { faGenderless, faMars, faVenus } from '@fortawesome/pro-regular-svg-icons';
import { ListGroupHeader } from 'components';
import { Translate, useApi, useConfig, useIntl } from 'providers';
import messages from 'messages';
import cx from 'classnames';
import { ReportValueStatus } from 'modules/reports/interfaces';
import { useAuthUser } from 'modules/auth/providers';
import { getCssColor } from 'utils/dom';
import { filter, find, intersection } from 'lodash';
import { useGuard } from 'containers';
import { useReportsConfig } from 'modules/reports/providers/ReportsConfigProvider';

export const PinnedReportGroupValue = 'PINNED_REPORT';

export const mapReportOverwriteColor = (color: string): string => {
  const found = {
    ffcfcf: Color.Red,
    ccffd8: Color.Green,
    fff9a8: Color.Yellow,
    fae3b4: Color.Yellow,
    ffbdbd: Color.Red,
    b6fcc7: Color.Green,
  }[color];
  return found ? getCssColor(found) : color;
};

export const getReportValueStatus = (reportValue: ReportValue) => {
  if (reportValue.pathological.length) {
    return ReportValueStatus.Pathological;
  }
  return reportValue.reference.text.length > 0 && !isNaN(parseFloat(reportValue.result)) ? ReportValueStatus.Ok : ReportValueStatus.Default;
};

export const getGenderIcon = (gender: Gender) => ({
  [Gender.Undetermined]: faGenderless,
  [Gender.Male]: faMars,
  [Gender.Female]: faVenus,
  [Gender.Diverse]: faGenderless,
  [Gender.Unknown]: faGenderless,
}[gender]);

const reportDetailLabels = messages.reports.details.label;

const reportValueWillFollow = ({ result }: ReportValueProperties) => result === '-folgt-' || result?.trim() === '';

export const useFormatReportValue = () => {

  const {
    preferences: {
      reportsValueFollowsEnabled,
      reportsValueFollowsHideNteEmpty,
      reportsValueFollowsText,
    },
  } = useConfig();

  return <T extends ReportValue, >(value: T, report?: Report) => {

    const { result, unit, textResult } = value;

    if (result === '.') {
      return null;
    } else if (reportValueWillFollow(value) && reportsValueFollowsEnabled) {
      if (report?.status === ReportStatus.Final || (reportsValueFollowsHideNteEmpty && value.meta?.length)) {
        return null;
      }

      return reportsValueFollowsText;
    }

    return (textResult || result) + ' ' + unit;
  };

};

export const hasValue = (value: ReportValue) => (value?.result?.length > 0 && value?.result !== '.') || value?.textResult?.length > 0;
export const hasPrevValue = (value: ReportValue) => value?.previous?.date && value?.previous?.result?.length > 0 && value?.previous?.result !== '.';
export const hasGraph = (value: ReportValue) => value?.graphicFormat !== ResultGraphicFormat.NoGraphic
  && !isNaN(parseFloat(value?.result))
  && (!isNaN(parseFloat(value?.reference?.from)) || !isNaN(parseFloat(value?.reference?.to)))
  && (value.graphicFormat === ResultGraphicFormat.AllergyGraphic || value?.reference?.text)
;
export const hasPreviousGraph = (value: ReportValue) => reportValueWillFollow(value) && !hasGraph(value) && hasGraph(value.previous);

export const hasNoValue = (values: ReportValue[]) => filter(values.map(hasValue)).length === 0;
export const hasNoPrevValue = (values: ReportValue[]) => filter(values.map(hasPrevValue)).length === 0;
export const hasNoGraph = (values: ReportValue[]) => filter(values.map(v => hasGraph(v) || hasPreviousGraph(v))).length === 0;
export const hasPathoText = (values: ReportValue[]) => filter(values.map(v => v?.pathological)).length > 0;
export const hasPrevPathoText = (values: ReportValue[]) => filter(values.map(v => v?.previous?.pathological)).length > 0;

export const MibiTableIndicators = ['antibiogramm', 'antimykogramm', 'topisches antibiogramm'];

export const filterClinicalValues = <T extends ReportValue, >(values: T[]) => values.filter(v => v.mibi === ReportValueMibiType.NoMibiValue);
export const filterMibiValues = <T extends ReportValue, >(values: T[]) => values.filter(v => v.mibi === ReportValueMibiType.IsMibiValue);
export const filterMibiTableValues = <T extends ReportValue, >(values: T[]) => values.filter(v => MibiTableIndicators.indexOf(v.group?.toLowerCase()) > -1);
export const filterNotMibiTableValues = <T extends ReportValue, >(values: T[]) => values.filter(v => MibiTableIndicators.indexOf(v.group?.toLowerCase()) === -1);

export const getReportValuesListHeader = (values: ReportValue[]): ListGroupHeader => (header, items) => {

  if (filterMibiTableValues(items).length > 0 || filter(items, i => i.id === 'mibi-tables').length > 0) {
    return undefined;
  }

  const hasMibi = filterMibiValues(items.filter(i => !!i.meta).map(i => i.meta)).length > 0;
  const hasValues = !hasNoValue(values.filter(v => v.group === header));
  const hasPrevValues = !hasNoPrevValue(hasValues ? values : values.filter(v => v.group === header));
  const showPathoColumn = hasPathoText(values);
  const showPrevPathoColumn = hasPrevPathoText(values);

  return {
    title: (
      <span className={'report-detail-header-wrapper'}>
        <span className={'report-detail-header-group'}>
          <span>{header || <Translate message={messages.reports.details.generalGroup}/>}</span>
        </span>
        {!hasMibi && showPathoColumn && (
          <span className={cx('report-detail-patho-column', 'is-hidden-mobile')}>
            &nbsp;
          </span>
        )}
        {!hasMibi && (hasValues || hasPrevValues) && (
          <span className={cx('report-detail-header-meta', 'report-detail-current-value', 'report-detail-value-column', 'is-hidden-mobile')}>
            <span>
              <b><Translate message={reportDetailLabels.value}/></b><br/>
              <Translate message={reportDetailLabels.valueRef}/>
            </span>
          </span>
        )}
        {!hasMibi && showPrevPathoColumn && (
          <span className={cx('report-detail-patho-column', 'is-hidden-mobile')}>
            &nbsp;
          </span>
        )}
        {!hasMibi && hasPrevValues && (
          <span className={cx('report-detail-header-meta', 'report-detail-value-column', 'is-hidden-mobile')}>
            <span>
              <b><Translate message={reportDetailLabels.previousValue}/></b><br/>
              <Translate message={reportDetailLabels.previousValueDate}/>
            </span>
          </span>
        )}
        {!hasMibi && !hasNoGraph(values) && (
          <span className={cx('report-detail-header-meta', 'report-detail-header-meta-wide', 'is-hidden-mobile')}>
            &nbsp;
          </span>
        )}
      </span>
    ),
  };
};

export const useCumulativeGrouping = () => {

  const { translate } = useIntl();

  const guard = useGuard();
  const featureEnabled = guard({ feature: Feature.ReportValuesCustomSort }, () => true);

  const { preferences: { reportsCustomSortCumulativeEnabled, reportsCustomSortCumulativeGroupsEnabled, reportsFavoriteAnalyses } } = useConfig();
  const withGroups = featureEnabled && reportsCustomSortCumulativeEnabled && reportsCustomSortCumulativeGroupsEnabled;

  const groupByValue = useCallback((value: ReportValue) => {
    if (reportsFavoriteAnalyses?.length > 0 && value.group === translate(messages.reports.sidebar.settings.tabs.analyses)) {
      return value.group;
    }
    return withGroups ? value.group || translate(messages.reports.details.generalGroup) : translate(messages.reports.details.otherAnalyses);
  }, [withGroups, reportsFavoriteAnalyses]);

  return useMemo(() => ({
    groupByValue,
    groupBy: withGroups || reportsFavoriteAnalyses?.length > 0 ? SortGrouping.STRING : undefined,
  }), []);

};

export const useShowPathoColumn = () => {

  const user = useAuthUser();
  const { reportDisplay } = useReportsConfig();

  const isPatient = !user || user?.type === UserType.PAT;
  return reportDisplay?.showPathoColumn && !(isPatient && reportDisplay?.hidePathoColumnPat);
};

export const useFavoriteAnalysesHistory = () => {

  const { translate } = useIntl();

  const { reports: { getReportHistory } } = useApi();
  const { preferences: { reportsFavoriteAnalyses } } = useConfig();

  return useCallback(async (bid: number) => {

    const result = await getReportHistory({ bid });

    const favoriteShortNames = reportsFavoriteAnalyses.map(a => a.shortName?.toLowerCase());
    const intersectingAnalyses = intersection(result.map(r => r.name?.toLowerCase()), favoriteShortNames);

    if (intersectingAnalyses.length) {
      return [
        ...intersectingAnalyses.map(name => ({
          ...find(result, r => r.name?.toLowerCase() === name),
          group: translate(messages.reports.sidebar.settings.tabs.analyses),
        })),
        ...result,
      ];
    }

    return result;

  }, [getReportHistory, reportsFavoriteAnalyses]);

};
