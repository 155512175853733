import React from 'react';
import { Progress } from 'antd';
import { ApiRequestCancelError, ApiRequestConfig, createCancelToken, useIntl } from 'providers';
import './FileTransfer.less';
import { asyncForEach } from 'modern-async';
import { useConfirmModal } from 'components';
import { downloadFile } from 'utils/file';
import messages from 'messages';

type Props<T> = {
  request: (data: T, config?: ApiRequestConfig) => Promise<any>;
  data: T | T[];
  config?: ApiRequestConfig;
};

type PropsWithLabels<T> = Props<T> & {
  labels: {
    title: React.ReactNode;
    preparing?: React.ReactNode;
    processing?: React.ReactNode;
  };
};

type BaseTransferFunction = <T>(props: PropsWithLabels<T>, isDownload: boolean) => Promise<any>;
type TransferFunction = <T>(props: Props<T>) => Promise<any>;

export const useFileTransfer = () => {

  const { translate } = useIntl();
  const confirmModal = useConfirmModal();

  const transfer: BaseTransferFunction = async (props, isDownload) => {

    let cancelToken = createCancelToken();

    let succeeded = 0;

    const renderTotal = () => {
      const percent = Math.floor(succeeded / (props.data as any).length * 100);
      return (<Progress className={'margin-top-1'} percent={percent} format={() => (succeeded + 1) + ' / ' + (props.data as any).length}/>);
    };

    let modal: any;

    const timeout = window.setTimeout(() => {
      modal = confirmModal({
        title: props.labels.title,
        content: (
          <div>
            {props.labels.preparing}
          </div>
        ),
        className: 'file-transfer',
        onCancel: () => {
          cancelToken.cancel();
          modal.destroy();
        },
      });
    }, 250);

    const update = (event: { loaded?: number; total?: number }) => {

      let percent = Math.floor(event.loaded / event.total * 100);

      if (percent === 100 && Array.isArray(props.data)) {
        percent = 0;
      }

      modal?.update({
        content: (
          <div>
            {(!Array.isArray(props.data) && percent === 100) ? props.labels.processing : <Progress percent={percent}/>}
            {Array.isArray(props.data) && renderTotal()}
          </div>
        ),
      });
    };

    const transferSingle = async (data: any) => {
      try {

        cancelToken = createCancelToken();

        const file = await props.request(data, {
          ...props.config,
          onDownloadProgress: isDownload && update,
          onUploadProgress: !isDownload && update,
          cancelToken: cancelToken.token,
          responseType: isDownload ? 'blob' : undefined,
        });

        isDownload && await downloadFile(file);

        return file;

      } catch (e) {
        modal?.destroy();
        clearTimeout(timeout);
        if (!(e instanceof ApiRequestCancelError)) {
          console.error(e);
          throw e;
        }
      }
    };

    if (Array.isArray(props.data)) {
      await asyncForEach(props.data, async (data) => {
        await transferSingle(data);
        succeeded++;
      });
      modal?.destroy();
      clearTimeout(timeout);
    } else {
      const result = await transferSingle(props.data);
      modal?.destroy();
      clearTimeout(timeout);
      return result;
    }

  };

  const download: TransferFunction = async (props) => {

    const labels = {
      title: translate(messages.general.fileTransfer.download),
      processing: translate(messages.general.fileTransfer.processing),
      preparing: translate(messages.general.fileTransfer.preparing),
    };

    return await transfer({ ...props, labels }, true);
  };

  const upload: TransferFunction = async (props) => {

    const labels = {
      title: translate(messages.general.fileTransfer.upload),
      processing: translate(messages.general.fileTransfer.processing),
      preparing: translate(messages.general.fileTransfer.preparing),
    };

    return await transfer({ ...props, labels }, false);
  };

  return { download, upload };

};
