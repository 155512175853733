import React from 'react';
import { OrderReason, OrderRequirementSchema, OrderWizardLocalization } from 'interfaces/api';
import { ModalFormControl } from 'components';
import { faLayerPlus } from '@fortawesome/pro-light-svg-icons';
import messages from 'messages';
import { useApi } from 'providers';
import { useOfficeDoctorContext, useOrdersConfig, useOrdersContext } from 'modules/orders/providers';
import { useBasketContext } from 'modules/orders/containers/OrderWizard/providers';
import { FormLayout } from 'containers';
import { splitEnumOptions } from 'utils/helpers';
import { isNull, keys, omitBy, pick } from 'lodash';
import { DiagnosesFormItem } from 'modules/orders/components';

const labels = messages.orders.wizard.profiles;
const additionalLabels = messages.orders.additionalFields;

export const CreateProfileControl = () => {

  const { profiles: { createProfile } } = useApi();

  const { officeDoctor: { aid, localisation }, reloadWizardSettings } = useOfficeDoctorContext();
  const { currentOrder, isPoolMode } = useOrdersContext();

  const { uniqRequirements } = useBasketContext();
  const { preferences } = useOrdersConfig();

  const requirements = uniqRequirements.map(r => pick(omitBy(r, isNull), keys(OrderRequirementSchema)));

  return (
    <ModalFormControl
      icon={faLayerPlus}
      label={labels.create.title}
      tooltip={{ placement: 'left' }}
      modal={{
        narrow: true,
        title: labels.create.title,
      }}
      notifications={labels.create.notifications}
      form={{
        initialValue: {
          aid,
          requirements,
          costUnit: isPoolMode ? null : currentOrder?.costUnit,
          name: undefined,
          diagnosis: '',
          freeText: '',
          orderReason: undefined,
          selectedDiagnoses: {},
          toAllOthers: false,
        },
        request: values => createProfile(values),
        onSuccess: () => {
          reloadWizardSettings(aid);
        },
        children: ({ Input, Radio, Switch, value, onChange }) => (
          <FormLayout>
            <Input property={'name'} label={labels.name} autoFocus/>
            {preferences.orderWizardProfilesSetDiagnoseText && (
              <Input property={'diagnosis'} label={messages.general.diagnosis}/>
            )}
            {preferences.orderWizardProfilesSetFreeText && (
              <Input property={'freeText'} label={messages.orders.additionalFields.freeText}/>
            )}
            <DiagnosesFormItem costUnit={currentOrder?.costUnit} value={value} onChange={onChange}/>
            {localisation === OrderWizardLocalization.GER && !preferences.orderWizardHideAdditionalInfo && (
              <Radio
                property={'orderReason'}
                values={splitEnumOptions(OrderReason, additionalLabels.orderReasons)}
                optionType="button"
                buttonStyle="solid"
              />
            )}
            {!isPoolMode && (
              <Switch property={'toAllOthers'} label={labels.controls.copy.toAllOthers.title}/>
            )}
          </FormLayout>
        ),
      }}
    />
  );
};
