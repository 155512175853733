import { DatePickerProps as AntDatePickerProps } from 'antd/es/date-picker';
import './DatePicker.less';
import { DatePicker as AntDatePicker } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { getLongDateFormat } from 'utils/helpers';

export type DatePickerProps = AntDatePickerProps & {
  disablePast?: boolean;
  disableFuture?: boolean;
  format?: string;
  withMask?: boolean;
};

export const DatePicker: React.FC<DatePickerProps> = (props) => {

  const { disablePast, disableFuture, withMask, ...rest } = props;

  const [value, setValue] = useState(props.value ? dayjs(props.value) : undefined);

  useEffect(() => setValue(props.value ? dayjs(props.value) : undefined), [props.value]);

  const disabledDateFn = useCallback((current: dayjs.Dayjs) => {
    if (disableFuture && current && current > dayjs().endOf('day')) {
      return true;
    } else if (disablePast && current && current < dayjs().startOf('day')) {
      return true;
    }
    return false;
  }, [disableFuture, disablePast]);

  const format = useMemo(() => props.format || 'L', [props.format]);

  const dateFormat = getLongDateFormat();
  const maskPattern = useMemo(() => format.replace('LT', 'HH:mm').replace('L', dateFormat), [format, dateFormat]);

  const onChange = useCallback((newValue: dayjs.Dayjs, newValueString: string) => {
    props.onChange(newValue, newValueString);
    setValue(newValue);
  }, [props.onChange, setValue]);

  return (
    <AntDatePicker
      {...rest}
      disabledDate={props.disabledDate || disabledDateFn}
      value={value}
      onChange={onChange}
      preserveInvalidOnBlur
      format={{
        format: maskPattern,
        type: 'mask',
      }}
    />
  );
};
