import React from 'react';
import messages from 'messages';
import { FormItemRenderProps } from 'components/Form/Item';
import { Feature, OrdersPreferencesProperties, OrderWizardLocalization, Product } from 'interfaces/api';
import { Guard } from 'containers';
import dayjs from 'dayjs';
import { useIntl } from 'providers';
import { usePatientInvoiceToOptions } from 'modules/orders/utils.tsx';

const labels = messages.admin.order.general;

type Props = FormItemRenderProps<OrdersPreferencesProperties> & {
  localisation: OrderWizardLocalization;
};

export const OrdersPreferencesForm: React.ComponentType<Props> = (props) => {

  const { Select, Switch, value, TimePicker, InputNumber, onChange, localisation } = props;
  const { translate } = useIntl();

  return (
    <>
      <Switch
        property={'ordersShowPrePrint'}
        label={labels.showPrePrint}
      />

      {value.ordersShowPrePrint && (
        <Switch
          property={'ordersPrePrintIsC2A'}
          label={labels.prePrintIsC2A}
        />
      )}

      <InputNumber
        label={labels.autocompleteEntries}
        property={'orderWizardAutocompleteEntries'}
      />

      <Switch
        property={'orderWizardAlwaysShowBlocks'}
        label={labels.alwaysShowBlocks.label}
        description={labels.alwaysShowBlocks.description}
      />

      <Switch
        property={'orderWizardProfilesSetDiagnoseText'}
        label={labels.profilesSetDiagnosisText}
      />

      <Switch
        property={'orderWizardProfilesSetFreeText'}
        label={labels.profilesSetFreeText}
      />

      <Switch
        property={'orderWizardAlwaysShowBlocks'}
        label={labels.alwaysShowBlocks.label}
        description={labels.alwaysShowBlocks.description}
      />

      <InputNumber
        label={labels.blockSize}
        property={'orderWizardBlockSize'}
      />

      <Guard feature={Feature.SampleInLaboratory}>
        <Switch
          property={'ordersSampleInLaboratoryButtonFirst'}
          label={labels.sampleInLaboratoryButtonFirst}
        />
      </Guard>

      <Switch
        property={'ordersScheduleTimeEnabled'}
        label={labels.scheduleTimeEnabled}
      />

      {value.ordersScheduleTimeEnabled && (
        <>
          <Select
            property={'ordersScheduleTimeInterval'}
            label={labels.scheduleTimeInterval}
            options={[
              { value: 1, label: '1 Minute' },
              { value: 5, label: '5 Minutes' },
              { value: 10, label: '10 Minutes' },
              { value: 15, label: '15 Minutes' },
              { value: 30, label: '30 Minutes' },
              { value: 60, label: '1 Hour' },
            ]}
          />
          <Switch
            property={'ordersScheduleTimeEnableDefault'}
            label={labels.scheduleTimeDefaultEnabled}
          />
          {value.ordersScheduleTimeEnableDefault && (
            <TimePicker
              property={'ordersScheduleTimeDefault'}
              label={labels.scheduleTimeDefault}
              format={'HH:mm'}
              minuteStep={value.ordersScheduleTimeInterval as any}
              allowClear={false}
              value={dayjs(value.ordersScheduleTimeDefault, 'HH:mm')}
              onChange={v => onChange({ ordersScheduleTimeDefault: v.format('HH:mm') })}
            />
          )}
        </>
      )}

      <Switch
        property={'ordersSplitClinical'}
        label={labels.bakSettings.splitClinical.label}
        description={labels.bakSettings.splitClinical.description}
      />

      <Guard feature={Feature.MassOrders}>
        <InputNumber
          property={'ordersMaxPoolSize'}
          label={labels.poolSettings.maxPoolSize}
        />
      </Guard>

      <Switch
        property={'orderWizardSearchDirectInput'}
        label={labels.searchDirectInput.label}
        description={labels.searchDirectInput.description}
      />

      <Switch
        property={'orderWizardSubRequirementsInBasket'}
        label={labels.subRequirementsInBasket.label}
        description={labels.subRequirementsInBasket.description}
      />

      <Switch
        property={'orderWizardNewOrderShowSelectPatient'}
        label={labels.newOrderShowSelectPatient.label}
        description={labels.newOrderShowSelectPatient.description}
      />

      {value.orderWizardNewOrderShowSelectPatient && (
        <Switch
          property={'orderWizardShowCostUnitSelect'}
          label={labels.showCostUnitSelect.label}
          description={labels.showCostUnitSelect.description}
        />
      )}

      <Select
        property={'orderWizardDefaultInvoiceTo'}
        label={messages.orders.wizard.sideBar.settings.defaultInvoiceTo}
        allowClear={false}
        options={[
          { value: '', label: translate(messages.orders.additionalFields.invoiceToOptions.defaultFromOrderForm) },
          { value: 'patient', label: translate(messages.orders.additionalFields.invoiceToOptions.patient) },
          { value: 'arzt', label: translate(messages.orders.additionalFields.invoiceToOptions.doctor) },
        ]}
      />

      {localisation === OrderWizardLocalization.CHE && (
        <>
          <Switch
            property={'orderWizardHideInsuranceInInvoiceTo'}
            label={labels.hideInsuranceInInvoiceTo.label}
            description={labels.hideInsuranceInInvoiceTo.description}
          />
          <Select
            property={'orderWizardDefaultReportCopySetting'}
            label={labels.defaultReportCopySetting.label}
            options={usePatientInvoiceToOptions(false)}
            allowClear
          />
        </>
      )}

      <Switch
        property={'orderWizardPopoverInvoiceTo'}
        label={labels.popoverInvoiceTo.label}
        description={labels.popoverInvoiceTo.description}
      />

      <Switch
        property={'orderWizardSeparateInvoiceTo'}
        label={labels.separateInvoiceTo.label}
        description={labels.separateInvoiceTo.description}
      />

      <Switch
        property={'orderWizardPopoverHideAnalyses'}
        label={labels.popoverHideAnalyses.label}
        description={labels.popoverHideAnalyses.description}
      />

      <Guard product={Product.LC}>
        <Switch
          property={'orderWizardShowWorkstationSelect'}
          label={labels.showWorkstationSelect.label}
          description={labels.showWorkstationSelect.description}
        />
      </Guard>

      {value.orderWizardShowWorkstationSelect && (
        <Guard product={Product.LC}>
          <Switch
            property={'orderWizardAlwaysShowWorkstationSelect'}
            label={labels.alwaysShowWorkstationSelect.label}
            description={labels.alwaysShowWorkstationSelect.description}
          />
          <Switch
            property={'orderWizardRememberLastWorkstation'}
            label={labels.rememberLastWorkstation.label}
            description={labels.rememberLastWorkstation.description}
          />
        </Guard>
      )}

      <Switch
        property={'orderWizardHideAdditionalInfo'}
        label={labels.hideAdditionalInfo.label}
        description={labels.hideAdditionalInfo.description}
      />

      <Switch
        property={'orderWizardDisableHelgaImage'}
        label={labels.disableHelgaImage.label}
        description={labels.disableHelgaImage.description}
      />

      <Switch
        property={'ordersPreferScheduleDateMode'}
        label={labels.preferScheduleDateMode.label}
        description={labels.preferScheduleDateMode.description}
      />

      <Guard feature={Feature.Anamnesis}>
        <Switch
          property={'ordersAnamnesisShrinkMode'}
          label={labels.anamnesisShrinkMode.label}
          description={labels.anamnesisShrinkMode.description}
        />
      </Guard>

      <Switch
        property={'ordersShowRoomNumber'}
        label={labels.showRoomNumber}
        description={labels.showRoomNumberDescription}
      />

      <Switch
        property={'ordersRealCancellation'}
        label={labels.realCancellation}
        description={labels.realCancellationDescription}
      />

      {value.ordersRealCancellation
        && (
          <InputNumber
            label={labels.realCancellationTimeRange}
            property={'ordersRealCancellationTimeRange'}
            intervalValue={value.ordersRealCancellationTimeRangeInterval}
            intervalOnChange={ordersRealCancellationTimeRangeInterval => onChange({ ordersRealCancellationTimeRangeInterval })}
          />
        )
      }

      <InputNumber
        label={labels.reportsTimeRange}
        property={'orderWizardReportsTimeRange'}
        intervalValue={value.orderWizardReportsTimeRangeInterval}
        intervalOnChange={orderWizardReportsTimeRangeInterval => onChange({ orderWizardReportsTimeRangeInterval })}
      />

      <Guard feature={Feature.PoolModule}>
        <Switch
          property={'orderSortPatientsDropdownByName'}
          label={labels.sortPatientsDropdownByName.label}
          description={labels.sortPatientsDropdownByName.description}
        />
      </Guard>

      <Guard feature={Feature.PoolModule}>
        <Switch
          property={'orderSortPoolPatientsByName'}
          label={labels.sortPoolPatientsByName.label}
          description={labels.sortPoolPatientsByName.description}
        />
      </Guard>

      {props.localisation === OrderWizardLocalization.CHE && (
        <Switch
          property={'orderWizardCopyToPatientActivationSwitch'}
          label={labels.copyToPatientActivationSwitch.label}
          description={labels.copyToPatientActivationSwitch.description}
        />
      )}

      <Guard feature={Feature.PoolModule}>
        <Switch
          property={'ordersShowBeforeAfterDialysis'}
          label={labels.poolSettings.showBeforeAfterDialysis}
        />

        {value.ordersShowBeforeAfterDialysis && (
          <Switch
            property={'ordersBeforeAfterDialysisMustBeSpecified'}
            label={labels.poolSettings.beforeAfterDialysisMustBeSpecified}
          />
        )}
      </Guard>

      <Switch
        property={'orderWizardPregnancySwitch'}
        label={labels.pregnancySwitch.label}
        description={labels.pregnancySwitch.description}
      />

      <Switch
        property={'orderWizardCheckupSwitch'}
        label={labels.checkupSwitch.label}
        description={labels.checkupSwitch.description}
      />

    </>
  );

};
