import { DoctorHierarchyStatus, LaboratoryUserPrivilegeMode, OrderWizardLocalization, ReportPathoHighlight } from 'interfaces/api';

export default {
  general: {
    id: 'User ID',
    lid: 'Laboratory ID',
    kennung: 'Username',
    target: 'Target',
    externalId: 'External ID',
    hierarchyAssignments: 'Assignments',
    title: 'Title',
    lastName: 'Last Name',
    firstName: 'First Name',
    address: 'Address',
    street: 'Street',
    streetNumber: 'Street Number',
    zip: 'Post Code',
    city: 'City',
    fax: 'Fax',
    phone: 'Phone',
    smsNumber: 'SMS Number',
    www: 'Homepage',
    email: 'E-Mail',
    displayName: 'Display Name',
    salutation: 'Salutation',
    doctors: 'Doctors',
    laboratory: 'Laboratory',
    laboratories: 'Laboratories',
    patients: 'Patients',
    welcomeName: 'Welcome Name',
    logoutName: 'Logout Name',
    personalData: 'Personal Data',
    password: 'Password',
    notifyMail: 'Notification email',
    rerequestMail: 'Rerequest email',
  },
  doctor: {
    aid: 'User ID',
    kennung: 'Login',
    lanr: 'LANR',
    kvnr: 'KV-Number',
    bsnr: 'BSNR',
    canActivatePatients: 'can activate Patients',
    isSuperDoctor: 'SuperDoc',
    disableViewDiagnosis: 'disable view diagnoses',
    emailNotification: 'receive e-mail notifications',
    emailReports: 'can send reports via mail',
    reportLanguage: 'Report Language',
    speciality: 'Speciality',
    internalPrintUser: 'Internal Print User',
    setKennung: 'Set kennung manually',
    sendActivationMail: 'Send activation mail',
    hierarchy: {
      assignments: 'Assignments',
      status: {
        placeholder: 'Hierarchy Status',
        none: 'None',
        [DoctorHierarchyStatus.NormalDoctor]: 'Normal doctor',
        [DoctorHierarchyStatus.HierarchyDoctor]: 'Hierarchy doctor',
      },
    },
    meNumber: 'ME Number',
    meExport: {
      active: 'Function active',
      recipientId: 'Recipient ID',
    },
    localization: {
      label: 'Localization',
      values: {
        [OrderWizardLocalization.AUT]: 'Doctor AUT',
        [OrderWizardLocalization.CHE]: 'Doctor CHE',
        [OrderWizardLocalization.GER]: 'Doctor GER',
        [OrderWizardLocalization.HUN]: 'Doctor HUN',
        [OrderWizardLocalization.KIS]: 'Clinic GER',
        [OrderWizardLocalization.VDE]: 'Veterinary GER',
        [OrderWizardLocalization.AIR]: 'Airport GER',
        [OrderWizardLocalization.FEN]: 'FEN',
      },
    },
    laboratoryLocalisation: {
      label: 'Laboratory Localization',
      values: {
        [OrderWizardLocalization.AUT]: 'Austria',
        [OrderWizardLocalization.CHE]: 'Switzerland',
        [OrderWizardLocalization.GER]: 'Germany',
        [OrderWizardLocalization.HUN]: 'Hungary',
        [OrderWizardLocalization.KIS]: 'Clinic',
      },
    },
  },
  laboratory: {
    loginSite: 'Login Website',
    logo: 'Logo',
    hl7Name: 'HL7 Name',
    contactMail: 'Contact Address',
    bdwSettings: 'BDW Settings',
    permissions: {
      label: {
        misc: 'General',
      },
      doctor: {
        changePassword: 'allow laboratory to change the password of doctors',
        changeUsername: 'allow laboratory to change the username of doctors',
        edit: 'allow laboratory to edit doctors',
        reactivate: 'allow laboratory to re-activate doctors',
        lock: 'allow laboratory to lock doctors',
        deactivate: 'allow laboratory to deactivate doctors',
        sendPasswordResetMail: 'allow laboratories to send a password reset link to doctors',
        accessLog: 'allow laboratory to access logs of doctors',
        delete: 'allow laboratory to delete doctors',
        activate: 'allow laboratory to activate doctors',
      },
      patient: {
        changePassword: 'allow laboratory to change the password of patients',
        changeUsername: 'allow laboratory to change the username of patients',
        edit: 'allow laboratory to edit patients',
        reactivate: 'allow laboratory to re-activate patients',
        lock: 'allow laboratory to lock patients',
        deactivate: 'allow laboratory to deactivate patients',
        sendPasswordResetMail: 'allow laboratories to send a password reset link to patients',
        accessLog: 'allow laboratory to access logs of patients',
        delete: 'allow laboratory to delete patients',
        activate: 'allow laboratory to activate patients',
      },
      misc: {
        deleteReport: 'allow laboratory to delete reports',
        pushtest: 'allow laboratory to perform a push test',
        viewReportProtocol: 'allow laboratory to view the report protocol',
        viewMails: 'allow laboratory to view sent mails in the report protocol',
      },
    },
    reportDisplay: {
      exportReports: 'Export Reports',
      contactLaboratory: 'Contact Laboratory',
      rerequests: 'Rerequests',
      reportAttachments: 'Report Attachments',
      emailReports: 'Email Reports',
      showPathoColumn: 'Show pathological column',
      hidePathoColumnPat: 'Hide pathological column for patients',
      showLaboratoryGroup: 'Show laboratory group in list',
      pathoHighlightType: 'Highlight of pathological values',
      pathoHighlightTypes: {
        [ReportPathoHighlight.Row]: 'The whole row is highlighted',
        [ReportPathoHighlight.Cell]: 'Only the value is highlighted',
      },
      alwaysShowDateAndTimeInCumulativeDisplay: 'Always show date and time',
      follows: {
        title: 'Value follows',
        enabled: {
          label: 'Automatically set value to a custom text when empty',
          description: 'This will not be applied to final reports',
        },
        text: 'Custom text for empty values',
        hideNte: 'Disable substitution for nte values',
      },
    },
  },
  laboratoryUser: {
    privilegeMode: {
      label: 'Laboratoryuser Role',
      modes: {
        [LaboratoryUserPrivilegeMode.Full]: 'Full Access',
        [LaboratoryUserPrivilegeMode.Orders]: 'only Online Orders',
        [LaboratoryUserPrivilegeMode.Reports]: 'only Online Reports',
        [LaboratoryUserPrivilegeMode.Activations]: 'only Order activation lists',
        [LaboratoryUserPrivilegeMode.Labwebshop]: 'only Labwebshop companies',
        [LaboratoryUserPrivilegeMode.MobileRecorder]: 'only mobile recorder',
      },
    },
  },
  patient: {
    reportLanguage: 'Report Language',
    partialReports: 'can view partial reports',
    preliminaryReports: 'can view preliminary reports',
  },
};
