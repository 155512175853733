import React from 'react';
import { DoctorBarcodeMode, Feature, OrdersUserConfig } from 'interfaces/api';
import messages from 'messages';
import { FormLayout, useGuard } from 'containers';
import { faCircleCheck, faCircleExclamation, faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { splitEnumOptions } from 'utils/helpers';
import { FormItem, FormItemRenderProps, Icon } from 'components';
import { Color } from 'interfaces';

const labels = messages.admin.order.printSettings;

export const PrintSettingsForm: React.ComponentType<FormItemRenderProps<OrdersUserConfig>> = (props) => {

  const { value, Property, onChange } = props;
  const guard = useGuard();

  const barcodeModes = splitEnumOptions(DoctorBarcodeMode, labels.barcodeMode.selectLabels)
    .filter(o => o.value !== DoctorBarcodeMode.CentralBarcodes || guard({ feature: Feature.CentralBarcodes }, () => true));

  const centralStatusIcon = value.centralBarcodes?.availableCount < (value.centralBarcodes?.threshold / 2)
    ? <Icon icon={faCircleXmark} tooltip={labels.statusHints.error} color={Color.Red} style={{ fontSize: 24 }}/>
    : value.centralBarcodes?.availableCount < value.centralBarcodes?.threshold
      ? <Icon icon={faCircleExclamation} tooltip={labels.statusHints.warning} color={Color.Yellow} style={{ fontSize: 24 }}/>
      : <Icon icon={faCircleCheck} tooltip={labels.statusHints.ok} color={Color.Green} style={{ fontSize: 24 }}/>;

  const alphanumericBarcodes = guard({ feature: Feature.AlphanumericBarcodes }, () => true);

  return (
    <>
      <Property property={'printSettings'}>
        {({ Select, Input, Switch }) => (
          <FormLayout label={labels.groupLabel.barcode} embedded>
            <Select
              property={'barcodeMode'}
              label={labels.barcodeMode.label}
              options={barcodeModes}
            />

            {value.printSettings?.barcodeMode === DoctorBarcodeMode.CentralBarcodes && (
              <>
                <FormItem label={labels.availableCount} className={'form-plain-text-wide'}>
                  <span>{value.centralBarcodes?.availableCount}</span>
                  <span>{centralStatusIcon}</span>
                </FormItem>
                {(value.centralBarcodes?.availableCount > 0) && (
                  <Property property={'centralBarcodes'}>
                    {({ Plaintext }) => (
                      <>
                        <Plaintext property={'nextBarcode'} label={labels.nextBarcode}/>
                        <Switch
                          property={'resetCentralBarcodes'}
                          label={labels.resetCentralBarcodes}
                          onChange={(v) => {
                            onChange({
                              ...value,
                              printSettings: {
                                ...value.printSettings,
                                resetCentralBarcodes: v,
                              },
                              centralBarcodes: {
                                ...value.centralBarcodes,
                                availableCount: 0,
                                nextBarcode: undefined,
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </Property>
                )}
              </>
            )}

            {value.printSettings?.barcodeMode !== DoctorBarcodeMode.CentralBarcodes && (
              <>
                <Input type={alphanumericBarcodes ? 'text' : 'number'} property={'printRangeMin'} label={labels.printRangeMin}/>
                <Input type={alphanumericBarcodes ? 'text' : 'number'} property={'printRangeMax'} label={labels.printRangeMax}/>

                <Input property={'printPrefix'} label={labels.printPrefix}/>
                <Input type={alphanumericBarcodes ? 'text' : 'number'} property={'printPosition'} label={labels.printPosition}/>

                <Switch property={'overwrite'} label={labels.overwrite}/>
                <Switch property={'useOwnNummernkreis'} label={labels.useOwnRange}/>
                <Switch property={'useLgNummernkreis'} label={labels.useLaboratoryGroupRange}/>
              </>
            )}
          </FormLayout>
        )}
      </Property>

      <Property property={'printSettings'}>
        {({ Switch }) => (
          <FormLayout label={labels.groupLabel.form} embedded>
            <Switch property={'socketPrint'} label={labels.socketPrint}/>
          </FormLayout>
        )}
      </Property>
    </>
  );
};
