import React from 'react';
import { Control, ControlProps, Input, InputFormat, useConfirmModal } from 'components';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import messages from 'messages';
import { useOrdersContext } from 'modules/orders/providers';
import { useIntl } from 'providers';

type EditBarcodeControlProps = Omit<ControlProps, 'onClick'> & {
  tnr: string;
  onDone?: (tnr: string) => void;
};

const labels = messages.orders.wizard.barcodes;

export const EditBarcodeControl = (props: EditBarcodeControlProps) => {

  const { tnr, onDone } = props;

  const { setOrderProperties } = useOrdersContext();

  const { translate } = useIntl();
  const confirmModal = useConfirmModal();

  const perform = () => {
    let editedTnr = tnr;
    confirmModal({
      title: translate(labels.edit.title),
      content: (
        <div>
          <Input
            maxLength={100}
            autoFocus
            defaultValue={tnr}
            format={InputFormat.Alphanumeric}
            onChange={(e) => {
              editedTnr = e.target.value;
            }}
            placeholder={translate(labels.edit.placeholder)}
          />
        </div>
      ),
      onOk: async () => {
        setOrderProperties({ tnr: editedTnr });
        onDone?.(editedTnr);
      },
    });
  };

  return (
    <Control
      icon={faPencil}
      onClick={perform}
    />
  );
};
