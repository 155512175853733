import React, { useCallback, useEffect, useState } from 'react';
import { useApi } from 'providers';
import { Select, SelectProps as SelectComponentProps } from 'components/index.ts';
import type { SelectProps } from 'antd';

export type AnalysisSelectProps = SelectComponentProps<any> & {
  // shortCodeValue?: boolean;
};

export const AnalysisSelect = (props: AnalysisSelectProps) => {

  const { globals: { getAutocomplete } } = useApi();

  const [data, setData] = useState<SelectProps['options']>();

  useEffect(() => {
    if (props.value) {
      getAutocomplete({ query: props.value, analysesShort: true }).then(result => setData(result.analyses.map(a => ({
        label: `${a.longName} (${a.shortName})`,
        value: a.shortName,
      }))));
    }
  }, []);

  const loadAnalyses = useCallback((query: string) => {
    getAutocomplete({ query, analysesAll: true }).then(result => setData(result.analyses.map(a => ({
      label: `${a.longName} (${a.shortName})`,
      value: a.shortName,
    }))));
  }, [getAutocomplete]);

  return (
    <Select
      showSearch
      style={props.style}
      defaultActiveFirstOption={false}
      filterOption={false}
      onSearch={loadAnalyses}
      notFoundContent={null}
      options={data || []}
      {...props}
    />
  );

};
